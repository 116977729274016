<template>
    <div class="home">
        <Header />
        <div class="spots-container">
            <div class="spots">
                <TouristSpot id="1" title="วัดพระใหญ่" description="Wat Phra Yai" :image="require('@/assets/wat-phra-yai.jpg')" />
                <TouristSpot id="2" title="หาดเชิงมน" description="Choeng Mon Beach" :image="require('@/assets/choeng-mon-beach.jpg')" />
                <TouristSpot id="3" title="น้ำตกหินลาด" description="Hin Lad Waterfall" :image="require('@/assets/hin-lad-waterfall.jpg')" />
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import Header from "@/components/Header.vue";
import TouristSpot from "@/components/TouristSpot.vue";
import Footer from "@/components/Footer.vue";

export default {
    name: "Home",
    components: {
        Header,
        TouristSpot,
        Footer
    }
};
</script>

<style scoped>
.home {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.spots-container {
    flex: 1;
}

.spots {
    display: flex;
    justify-content: space-around;
    margin: 20px 0;
}
</style>
