<template>
    <header class="header">
        <h1>หน้าหลัก สถานที่ท่องเที่ยว</h1>
    </header>
</template>

<script>
export default {
    name: "Header"
};
</script>

<style scoped>
.header {
    background-color: #000;
    color: #fff;
    text-align: center;
    padding: 10px 0;
}
</style>