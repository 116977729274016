<template>
    <div class="detail-page">
        <!-- Hamburger Menu Icon -->
        <div class="hamburger-icon" @click="toggleMenu">
            <div :class="{ 'bar1': isMenuOpen }"></div>
            <div :class="{ 'bar2': isMenuOpen }"></div>
            <div :class="{ 'bar3': isMenuOpen }"></div>
        </div>

        <!-- Sidebar Menu -->
        <nav :class="['sidebar', { 'open': isMenuOpen }]">
            <ul>
                <li><a href="#" @click.prevent="scrollToSection(detail.title)">{{ detail.title }}</a></li>
                <li><a href="#" @click.prevent="scrollToSection('section1')">แผนที่</a></li>
                <li><a href="#" @click.prevent="scrollToSection('section2')">ร้านอาหาร</a></li>
                <li><a href="#" @click.prevent="scrollToSection('section3')">โรงแรม</a></li>
                <li><a href="#" @click.prevent="scrollToSection('section4')">สถานีตำรวจ</a></li>
                <li><a href="#" @click.prevent="scrollToSection('section5')">โรงพยาบาล</a></li>
                <li><a href="#" @click.prevent="scrollToSection('section6')">ชุมชน</a></li>
                <li @click="onClickBackHomePage"><a>กลับหน้าแรก</a></li>
            </ul>
        </nav>

        <!-- Main Content -->
        <div class="content">
            <div :id="detail.title" v-if="detail" class="section">
                <!-- วิธีใส่รูป -->
                <!-- <div v-if="detail && detail.imageMap">
                        <img :src="require(detail.imageMap)" />
                    </div> -->
                <h2>{{ detail.title }}</h2>

                <div id="section1" class="section">
                    <div v-if="detail && detail.imageMap" class="mb-5">
                        <img :src="require('@/assets/image/choeng-mon-beach.jpg')" />
                    </div>
                    <h2>แผนที่</h2>
                    <p>{{ detail.map }}</p>
                </div>

                <div id="section2" class="section">
                    <h2>ร้านอาหาร</h2>
                    <p>{{ detail.restaurants }}</p>
                </div>

                <div id="section3" class="section">
                    <h2>โรงแรม</h2>
                    <p>{{ detail.hotels }}</p>
                </div>

                <div id="section4" class="section">
                    <h2>สถานีตำรวจ</h2>
                    <p>{{ detail.police_station }}</p>
                </div>

                <div id="section5" class="section">
                    <h2>โรงพยาบาล</h2>
                    <p>{{ detail.hospital }}</p>
                </div>

                <div id="section6" class="section">
                    <h2>ชุมชน</h2>
                    <p>{{ detail.community }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import rawData from '@/assets/details.json';

export default {
    name: "DetailPage",
    data() {
        return {
            detail: null,
            isMenuOpen: false, // สำหรับควบคุมสถานะของเมนู
        };
    },
    props: ['id'],
    created() {
        this.detail = rawData.find(item => item.id === parseInt(this.id));
    },
    methods: {
        onClickBackHomePage() {
            this.$router.push({ name: 'Home' });
        },
        scrollToSection(sectionId) {
            const section = document.getElementById(sectionId);
            if (section) {
                section.scrollIntoView({ behavior: 'smooth' });
                this.isMenuOpen = false; // ปิดเมนูเมื่อเลือก section
            }
        },
        toggleMenu() {
            this.isMenuOpen = !this.isMenuOpen;
        }
    }
};
</script>

<style scoped>
.detail-page {
    display: flex;
}

/* Hamburger Menu Icon */
.hamburger-icon {
    position: fixed;
    top: 20px;
    left: 20px;
    cursor: pointer;
    z-index: 1000;
}

.hamburger-icon div {
    width: 35px;
    height: 5px;
    background-color: #333;
    margin: 6px 0;
    transition: 0.4s;
}

/* Change Icon to X when Menu is Open */
.bar1 {
    transform: rotate(-45deg) translate(-9px, 6px);
}

.bar2 {
    opacity: 0;
}

.bar3 {
    transform: rotate(45deg) translate(-8px, -8px);
}

/* Sidebar */
.sidebar {
    position: fixed;
    top: 0;
    left: -175px;
    width: 250px;
    height: 100%;
    background-color: #f0f0f0;
    padding: 20px;
    transition: 0.4s;
    z-index: 999;
}

.sidebar.open {
    left: 0;
}

.sidebar ul {
    list-style: none;
    padding: 0;
}

.sidebar ul li {
    margin-bottom: 20px;
}

.sidebar ul li a {
    text-decoration: none;
    color: #000;
    font-weight: bold;
    cursor: pointer;
}

/* Main Content */
.content {
    margin-left: 220px;
    padding: 20px;
    width: calc(100% - 220px);
}

.section {
    margin-bottom: 100px;
}

.section h2 {
    font-size: 24px;
    margin-bottom: 20px;
}

@media (max-width: 768px) {
    .content {
        margin-left: 0;
        width: 100%;
    }

    .hamburger-icon {
        top: 10px;
        left: 10px;
    }
}
</style>